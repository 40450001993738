import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import Vimeo from '@u-wave/react-vimeo';
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-2020-stories/more-stories"
import StoryNav from "../../components/spring-2020-stories/story-nav"
import Related from "../../components/spring-2020-stories/related-stories"
import { withPrefix } from 'gatsby'

import socialBanner from "../../images/spring-2020/social-banners/calling-in-counseling.jpg";

// import Mir from '../../images/spring-2020/mir.jpg';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Project Rebound Alumnus Finds Calling in Counseling';
    var pageDesc = 'As if transferring from a small community college to a large university isn’t daunting enough, imagine needing a wheelchair and having a history of incarceration. Mir Aminy initially felt lost and struggled to find his place at Cal State Fullerton.';


    var heroStyles = {
      backgroundColor: '#222',
      // backgroundImage: `url(${Mir})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: 'white'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-2020">
        <Helmet>
          <title>{pageTitle}</title>

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
            <span className="animated fadeInDown delay-1s meta">Story by Karen Lindell and Lynn Juliano, Photos by Matt Gush</span>
          </div>
          <div className="tint"></div>
          <video id="heroVideo" autoPlay="autoplay" loop muted="muted" poster={withPrefix('/video/mir-background.jpg')} >
            <source src={withPrefix('/video/mir-background.webm')} type="video/webm; codecs=vp9,vorbis" />
            <source src={withPrefix('/video/mir-background.mp4')} />
            <track kind="captions" srcLang="en" label="English" src={withPrefix('/video/mir-background.vtt')} />
          </video>
        </div>
        <article className="wrap small">
        
          
          <ScrollAnimation animateIn="fadeIn">
          
          <Related slug="calling-in-counseling" />
          
          <p><span className="intro-text">As if transferring from a small community college</span> to a large university isn’t daunting enough, imagine needing a wheelchair and having a history of incarceration. Mir Aminy initially felt lost and struggled to find his place at Cal State Fullerton.</p>

          <p>Then Aminy discovered Project Rebound, a campus program that assists formerly incarcerated individuals in navigating higher education. Project Rebound helped him develop into an involved student and community member, while also giving him a place to be himself.</p>
          
          <p>After successfully completing his bachelor’s degree, Aminy ’19 (B.A. sociology) now works for Project Rebound while pursuing a master’s in counseling. He is on his way to achieving his dream of working with higher education students who face the same challenges he has faced.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="zoomIn">
          <Vimeo
            video="393991778"
            className="align-wide video"
            responsive
            showTitle="false"
            showByline="false"
            showPortrait="false"
          />
          </ScrollAnimation>


          <ScrollAnimation animateIn="fadeIn">
          <blockquote className="pull-quote"><p>“There’s no such thing as a throwaway human being.”</p></blockquote>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>Aminy also mentors undergraduate men on campus and local youth at the Higher Ground Youth & Family Services after-school program in Anaheim. He has been recognized for his extraordinary commitment, selfless service and positive impact on those around him.</p>

          <p>“I don’t think there is any such thing as a throwaway human being,” he says. “I know how easy it is to go down that wrong path. Higher education is the key to changing the narrative.” <span className="end-mark" /></p>

          <p className="learn-more">To learn more about Project Rebound, visit <a href="https://www.fullerton.edu/rebound/" target="_blank">fullerton.edu/rebound</a>.</p>
          </ScrollAnimation>
          

        </article>
        <MoreStories slug="calling-in-counseling" />
        <StoryNav slug="calling-in-counseling" />
      </Layout>
    );
  }
} 